
import { defineComponent } from 'vue'
import Time from '@/components/Time.vue'
import Money from '@/components.global/Money'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'

export default defineComponent({
  name: 'TheItem',
  components: { Holder, List, Money, Time },
  props: {
    list: {
      type: Array,
    },
    used: {
      type: Boolean,
    },
  },
})

