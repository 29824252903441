
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import TheItem from '@/pages/coupons/components/TheItem.vue'
import { readVoucherList, VoucherSchema, VoucherStatus } from '@/pages/coupons/coupons.api'

export default defineComponent({
  name: 'Coupons',
  components: { TheItem, Tab, PageWithHeader },
  setup () {
    const sel = ref(0)
    const list = ref<Array<VoucherSchema> | null>(null)

    const getCoupons = (index: number) => {
      const data = {
        type: 1,
        page: 1,
        pageCount: 1000,
        status: index === 0 ? VoucherStatus.USABLE : `${VoucherStatus.USED},${VoucherStatus.EXPIRED}`,
      }
      readVoucherList(data)
        .then(resp => list.value = resp as Array<VoucherSchema>)
    }

    getCoupons(sel.value)

    const getActive = (index: number) => {
      sel.value = index
      getCoupons(index)
    }

    return { sel, getActive, list }
  },
})
